import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import scrollTo from 'gatsby-plugin-smoothscroll';

import Layout from "../components/layout"
import FullScreenSection from "../components/full-screen-section"
import Seo from "../components/seo"

import styled from 'styled-components'

const IndexPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "bg_3d_bn_1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "bg_3d_bn_2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg3:
        file(relativePath: { eq: "bg_3d_bn_3.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg4:
        file(relativePath: { eq: "bg_3d_bn_4.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }                

      }
    `
  )

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid
  const bg3 = data.bg3.childImageSharp.fluid
  const bg4 = data.bg4.childImageSharp.fluid

  React.useEffect(() => {
    // let iframes = document.querySelectorAll('iframe');
    // // console.dir(iframes[1].clientHeight);
    // iframes[0].style.height = iframes[1].clientHeight * 2 + 'px';
  }, [])

  return (
    <Layout>
      <Seo title="Experiencia 3D" />
      <FullScreenSection id="sketchfab">

        <BackgroundImage
          Tag="section"
          className={'container-fluid g-0 fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column `}>
            <p className={`text-white headline pb-0`} ><span className={`bg-black`}>Modelo 3d /</span><span className={`fs-5 bg-black d-none d-md-inline-block d-lg-inline-block d-xl-inline-block`}>Desplázate por la volumetría del Mural.</span></p>
          </div>

          <div className={`sketchfab-embed-wrapper p-3`}>
            <iframe title="Mural del Paso Inferior Santa Lucía 3D." frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/10b9720ec26142bd9d314c1ca58d48d9/embed"></iframe>
          </div>

          <div className={`threed-nav col-12`}>
            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`} onClick={() => scrollTo('#recorrido-1')}>
              <p className={`text-white p-1 ps-3`}>Recorridos 360 <i class="fa fa-chevron-down" aria-hidden="true"></i></p>
            </div>

            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`} onClick={() => scrollTo('#recorrido-2')}>
              <a href="#vista-1"><p className={`text-white p-1 ps-3`}>Vista 360 <i class="fa fa-chevron-down" aria-hidden="true"></i></p></a>
            </div>            
          </div>
            
          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>

      <FullScreenSection id="recorrido-1">

        <BackgroundImage
          Tag="section"
          className={'container-fluid g-0 fullheight-ribbon d-flex flex-column'}
          fluid={bg2}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column `}>
            <p className={`text-white headline pb-0`} ><span className={`bg-black`}>Recorridos 360 /</span><span className={`bg-black fs-5 d-none d-md-inline-block d-lg-inline-block d-xl-inline-block`}>Recorridos en velocidad.</span></p>
          </div>

          <div className={`youtube-embed-wrapper p-3`}>
            <iframe className={``} src="https://www.youtube.com/embed/70ufl-Yx5g4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>    

          <div className={`threed-nav col-12`}>
            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#sketchfab')}>Modelo 3d <i className={`fa fa-chevron-up`} aria-hidden="true"></i></p>
            </div>

            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#vista-1')}>Vista 360 <i className={`fa fa-chevron-down`} aria-hidden="true"></i></p>
            </div>            
          </div>
            
          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>        

      </FullScreenSection>

      <FullScreenSection id="recorrido-2">

        <BackgroundImage
          Tag="section"
          className={'container-fluid g-0 fullheight-ribbon d-flex flex-column'}
          fluid={bg3}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column `}>
            <p className={`text-white headline pb-0`} ><span className={`bg-black`}>Recorridos 360 /</span><span className={`bg-black fs-5 d-none d-md-inline-block d-lg-inline-block d-xl-inline-block`}>Recorridos en velocidad.</span></p>
          </div>

          <div className={`youtube-embed-wrapper p-3`}>
            <iframe className={``} src="https://www.youtube.com/embed/c0EpyPh3S_o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>    

          <div className={`threed-nav col-12`}>
            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#sketchfab')}>Modelo 3d <i className={`fa fa-chevron-up`} aria-hidden="true"></i></p>
            </div>

            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#vista-1')}>Vista 360 <i className={`fa fa-chevron-down`} aria-hidden="true"></i></p>
            </div>            
          </div>
            
          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>        

      </FullScreenSection>

      <FullScreenSection id="vista-1">

        <BackgroundImage
          Tag="section"
          className={'container-fluid g-0 fullheight-ribbon d-flex flex-column'}
          fluid={bg4}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column `}>
            <p className={`text-white headline pb-0`} ><span className={`bg-black`}>Vista 360 /</span><span className={`bg-black fs-5 d-none d-md-inline-block d-lg-inline-block d-xl-inline-block`}>Punto de vista peatonal.</span></p>
          </div>

          <div className={`youtube-embed-wrapper p-3`}>
            <iframe className={``} src="https://www.youtube.com/embed/bXzwPUyu6BE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>    

          <div className={`threed-nav col-12`}>
            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#sketchfab')}>Modelo 3d<i class="fa fa-chevron-up" aria-hidden="true"></i></p>
            </div>

            <div className={`threed-nav-down col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#recorrido-1')}>Recorridos 360 <i class="fa fa-chevron-up" aria-hidden="true"></i></p>
            </div>            
          </div>         
            
          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>        

      </FullScreenSection>

      <FullScreenSection id="vista-2">

        <BackgroundImage
          Tag="section"
          className={'container-fluid g-0 fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column `}>
            <p className={`text-white headline pb-0`} ><span className={`bg-black`}>Vista 360 /</span><span className={`bg-black fs-5 d-none d-md-inline-block d-lg-inline-block d-xl-inline-block`}>Punto de vista peatonal.</span></p>
          </div>

          <div className={`youtube-embed-wrapper p-3`}>
            <iframe className={``} src="https://www.youtube.com/embed/AlF5mga00kw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>    

          <div className={`threed-nav col-12`}>
            <div className={`threed-nav-up col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#sketchfab')}>Modelo 3d <i class="fa fa-chevron-up" aria-hidden="true"></i></p>
            </div>

            <div className={`threed-nav-up-up col-md-6 offset-md-6 col-lg-4 offset-lg-8 col-xl-3 offset-xl-9 col-xs-6 col-sm-6 pointer-cursor`}>
              <p className={`text-white p-1 ps-3`} onClick={() => scrollTo('#recorrido-1')}>Recorridos 360 <i class="fa fa-chevron-up" aria-hidden="true"></i></p>
            </div>            
          </div>         
            
          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>        

      </FullScreenSection>      

    </Layout>
  )

}

const StyledBackgroundSection = styled(IndexPage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default IndexPage
